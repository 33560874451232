<template>
<div class='tencent-put'>
      <router-view></router-view>
</div>
</template>

<script>

export default {
data() {
return {

};
},
mounted() {

},
watch:{
},
methods: {

},
components: {},
}
</script>
<style lang='scss' scoped>
</style>